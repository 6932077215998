<template>
  <div>
    <div class="pa-3" v-if="!loadingPage">
      <div class="mb-3 d-flex justify-space-between">
        <v-btn small fab depressed color="green" dark @click="getBack">
          <v-icon>mdi-reply</v-icon>
        </v-btn>
        <header>
          <h1 class="text-center text-capitalize">
            Update report {{ $route.params.periode }}
          </h1>
        </header>
        <div></div>
      </div>
      <div class="pa-3 d-flex justify-center">
        <div class="rounded-max _80w px-5">
          <div v-if="form" class="pa-3">
            <v-row>
              <v-col cols="4"> Nama Lengkap </v-col>
              <v-col cols="8">
                <h3>
                  {{ score.data_scholar.nama_lengkap }}
                </h3>
              </v-col>
            </v-row>
            <section v-for="(item, idx) in form.data" :key="idx">
              <v-row v-if="item.type == 'integer' && item.label !== 'Scholar'">
                <v-col cols="4">
                  {{ item.label }}
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="model[item.attribute]"
                    hide-details="auto"
                    :label="item.label"
                    :error-messages="
                      validateInt[item.attribute] == true
                        ? 'Data yang di input harus berupa integer'
                        : ''
                    "
                    required
                    @blur="isInt(model[item.attribute], item.attribute)"
                    solo
                    dense
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row v-if="item.type == 'text'">
                <v-col cols="4"> {{ item.label }} </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="model[item.attribute]"
                    hide-details="auto"
                    :label="item.label"
                    required
                    solo
                    @blur="updateReport(false)"
                    dense
                  ></v-text-field
                ></v-col>
              </v-row>
              <v-row v-if="item.type == 'textarea'">
                <v-col cols="4"> {{ item.label }} </v-col>
                <v-col cols="8">
                  <v-card
                    width="100%"
                    height="150px"
                    class="pa-3"
                    style="cursor: pointer"
                    @click.native="showTxt(item)"
                  >
                    <p class="grey--text" v-if="!model[item.attribute]">
                      {{ item.placeholder }}
                    </p>
                    <p v-if="model[item.attribute]">
                      <span v-snip="5" v-html="model[item.attribute]"></span>
                    </p>
                  </v-card>
                  <!-- <v-textarea
                    v-model="model[item.attribute]"
                    hide-details="auto"
                    :label="item.label"
                    required
                    solo
                    dense
                  ></v-textarea> -->
                </v-col>
              </v-row>
              <v-row
                v-if="item.type == 'dropdown' && item.attribute !== 'periode'"
              >
                <v-col cols="4"> {{ item.label }} </v-col>
                <v-col cols="8">
                  <v-select
                    :items="item.value"
                    dense
                    v-model="model[item.attribute]"
                    hide-details
                    :label="item.label"
                    solo
                    @focus="setPrevVal(model[item.attribute])"
                    @change="showConfirm(model[item.attribute], item)"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="item.type == 'daterange'">
                <v-col cols="4"> {{ item.label }} </v-col>
                <v-col cols="8">
                  <v-menu
                    ref="menu"
                    v-model="calendar1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateRangeTxt"
                        :label="item.placeholder"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        dense
                        solo
                      ></v-text-field>
                    </template>
                    <v-date-picker range v-model="date_range">
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="calendar1 = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="saveDate(date_range)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu></v-col
                >
              </v-row>
            </section>
            <footer class="mt-3">
              <v-row>
                <v-col cols="4"></v-col>
                <v-col cols="8">
                  <div class="d-flex justify-space-between">
                    <div>
                      <v-alert dense outlined type="info">
                        Last Modified : &nbsp;
                        {{ $date(score.data.updated_at).format("DD/MM/YYYY") }}
                        &nbsp;
                        {{ $date(score.data.updated_at).format("HH:mm") }}
                      </v-alert>
                    </div>
                    <div>
                      <v-btn
                        @click="updateReport(true)"
                        v-if="!loading"
                        color="green"
                        dark
                        class="text-capitalize"
                        >Save</v-btn
                      >
                      <v-btn
                        v-if="loading"
                        color="green"
                        class="text-capitalize"
                        dark
                        depressed
                        ><v-progress-circular
                          :size="25"
                          class="mr-2"
                          indeterminate
                        ></v-progress-circular>
                        Loading</v-btn
                      >
                    </div>
                  </div>
                </v-col>
              </v-row>
            </footer>
          </div>
        </div>
      </div>
    </div>
    <div class="pa-2">
      <ConfirmChangeScore
        v-bind:dialogChange="dialogChange"
        v-bind:dataItem="dataChange"
        @changeScore="changeScore"
      />
      <TextareaInput
        v-bind:dialogTextarea="dialogTextarea"
        v-bind:dataTxt="dataTxt"
        v-bind:typed="typed"
        @close="closeDialog(1)"
        @save="mutateTxt"
        ref="txtEditor"
      />
    </div>
    <div v-if="loadingPage">
      <loading />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapState } from "vuex";
import loading from "../../../components/etc/loading-component/loadingLG.vue";
import TextareaInput from "../../../components/Report/Create/Modal/textareaInput.vue";
import ConfirmChangeScore from "./confirmChangeScore.vue";

export default {
  components: { loading, TextareaInput, ConfirmChangeScore },
  name: "updateReport",
  computed: {
    ...mapState({
      form: (state) => state.report.form_report,
      score: (state) => state.report.score,
    }),
  },
  data() {
    return {
      model: {},
      validateInt: {},
      loading: false,
      loadingPage: false,
      calendar1: false,
      dateRangeTxt: "",
      date_range: [],
      dialogTextarea: false,
      dataTxt: null,
      typed: null,
      temporaryVal: null,
      dialogChange: false,
      dataChange: null,
    };
  },
  mounted() {
    this.getScore(true);
  },
  methods: {
    getBack() {
      this.$router.go(-1);
    },
    saveDate(time) {
      this.$refs.menu[0].save(time);
      this.formatedDate(time);
    },
    formatedDate(time) {
      let isYearSame =
        this.$date(time[0]).format("YYYY") ==
        this.$date(time[1]).format("YYYY");
      let isMontSame =
        this.$date(time[0]).format("MM") == this.$date(time[1]).format("MM");
      if (isYearSame) {
        if (isMontSame) {
          this.dateRangeTxt = `${this.$date(time[1]).format("MMMM YYYY")}`;
        } else {
          this.dateRangeTxt = `${this.$date(time[0]).format(
            "MMMM"
          )} - ${this.$date(time[1]).format("MMMM YYYY")}`;
        }
      } else {
        this.dateRangeTxt = `${this.$date(time[0]).format(
          "MMMM YYYY"
        )} - ${this.$date(time[1]).format("MMMM YYYY")}`;
      }
    },
    isInt(val, attr) {
      if (val !== null) {
        if (Number.isInteger(parseInt(val))) {
          this.model[attr] = parseInt(this.model[attr]);
          this.validateInt[attr] = false;
        } else {
          this.$set(this.validateInt, attr, true);
          this.$set(this.model, attr, null);
          console.log("Data yang di input harus berupa integer");
        }
      }
    },
    getForm() {
      this.$store
        .dispatch("report/getForm", this.$route.params.periode)
        .then(() => {
          setTimeout(() => (this.loadingPage = false), 1000);
        });
    },
    getScore(isLoading) {
      if (isLoading == true) {
        this.loadingPage = true;
      }
      let data = {
        id: this.$route.params.id,
        periode: this.$route.params.periode,
      };
      this.$store.dispatch("report/viewScore", data).then((data) => {
        let result = { ...data.data };
        for (const key in result) {
          if (
            key !== "data_scholar" &&
            key !== "fetch_i_q" &&
            key !== "updated_by" &&
            key !== "updated_at"
          )
            this.model[key] = result[key];
          this.validateInt[key] = false;
          if (key == "range_periode") {
            this.date_range = result[key];
            this.formatedDate(result[key]);
          }
        }
        this.getForm();
      });
    },
    updateReport(isLoading) {
      if (isLoading == true) {
        this.loading = true;
      }
      this.model["range_periode"] = this.date_range;
      this.model["periode_pemeriksaan_from"] = this.date_range[0];
      this.model["periode_pemeriksaan_to"] = this.date_range[1];
      this.$store.dispatch("report/updateReport", this.model).then((data) => {
        this.getScore();
        if (isLoading == true) {
          this.loading = false;
          Swal.fire({
            icon: "success",
            title: data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000,
          });
        }
      });
    },
    setPrevVal(val) {
      this.temporaryVal = val;
    },
    showConfirm(val, item) {
      this.dataChange = {
        val: val,
        item: item,
      };
      this.dialogChange = true;
    },
    changeScore(isConfirmed, data) {
      if (isConfirmed) {
        this.model[data.item.attribute] = data.val;
        this.temporaryVal = null;
        this.updateReport(false);
      } else {
        this.model[data.item.attribute] = this.temporaryVal;
        this.model = Object.assign({}, this.model, this.model);
        this.temporaryVal = null;
      }
      this.dialogChange = false;
    },
    mutateTxt(data) {
      this.model[data.attr] = data.txt;
      this.closeDialog(1);
      this.updateReport(false);
    },
    showTxt(item) {
      this.dataTxt = item;
      this.dialogTextarea = true;
      this.$refs.txtEditor.mutableTxt(this.model[item.attribute]);
    },
    closeDialog(q) {
      switch (q) {
        case 1:
          this.dialogTextarea = false;
          this.dataTxt = null;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style>
.btn-group {
  display: flex !important;
  justify-content: flex-end !important;
}
.cancel-btn {
  color: red !important;
  background: none !important;
  outline: none !important;
}
.confirm-btn {
  background: #4caf50 !important;
  outline: none !important;
}
.confirm-btn:focus {
  outline: none !important;
}
.title-confrm {
  font-size: 19px;
}
</style>
