<template>
  <v-row justify="start">
    <v-dialog v-model="dialogTextarea" scrollable persistent max-width="600px">
      <v-card class="rounded-max" v-if="dataTxt">
        <v-toolbar dense flat>
          <h3>{{ dataTxt.label }}</h3>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="px-2">
          <v-container>
            <vue-editor
              :placeholder="dataTxt.label"
              :editorToolbar="customToolbar"
              class="mb-3"
              v-model="isi"
            ></vue-editor>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="text-capitalize"
            depressed
            color="green"
            dark
            @click="saveTxt"
            >Ok</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
  name: "dialogTextarea",
  components: {
    VueEditor,
  },
  computed: {
    ...mapState({
      user: (state) => state.user.list_user,
    }),
  },
  data() {
    return {
      loading: false,
      isi: "",
      mutableTyped: this.typed,
      customToolbar: [
        ["bold", "italic", "underline"]
      ],
    };
  },
  props: ["dialogTextarea", "dataTxt", "typed"],

  methods: {
    mutableTxt(txt) {
      this.isi = txt;
    },
    saveTxt() {
      let data = {
        attr: this.dataTxt.attribute,
        txt: this.isi.replace('<span style="color: black;">', '').replace('<span>', '').replace('class="ql-align-justify"', '').replace('</span>', '').replace('&nbsp;', ''),
      };
      this.$emit("save", data);
      this.isi = "";
    },
  },
};
</script>

<style scoped>
</style>
