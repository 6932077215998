<template>
  <v-row justify="start">
    <v-dialog v-model="dialogChange" persistent max-width="400px">
      <v-card class="rounded-max">
        <v-container v-if="dataItem">
          <div class="mb-3 d-flex justify-center flex-column align-center">
            <img src="../../../assets/img/warncon.png" height="130px" alt="" />
            <h3 class="text-center mt-3">
              Are you sure want to change <b>{{ dataItem.item.label }}</b> score?
            </h3>
          </div>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="text-capitalize"
            text
            @click="changeItem(false)"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="!loading"
            color="green"
            class="text-capitalize"
            depressed
            dark
            @click="changeItem(true)"
          >
            <p class="ma-0">Yes</p></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "deleteReport",
  data() {
    return {
      loading: false,
    };
  },
  props: ["dialogChange", "dataItem"],

  methods: {
    changeItem(status) {
      this.$emit("changeScore", status, this.dataItem);
    },
  },
};
</script>

<style></style>
